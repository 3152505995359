
import shared from '../shared.js'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

export interface Members {
   name: string;
   value: string;
}

export interface Team {
   text: string;
   value: string;
}

export default {
  name: 'TeamHolder',
  mixins: [shared],
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dialogModal: false,
      validTeamForm: false,
      search: '',
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: this.$t('age-group'), value: 'ageGroup' },
        { text: this.$t('gender'), value: 'gender' },
        { value: 'actions', sortable: false }
      ],
      teamForm: {
        name: '',
        nameRules: [
          (v: any) => !!v || this.$t('team-name-required')
        ],
        staff: [],
        staffRules: [
          (v: any) => !!v || this.$t('user-role-required')
        ],
        gender: '',
        ageGroup: ''
      },
      gender: ['Female', 'Male'],
      message: this.$t('confirm-delete')

    }
  },
  created () {
    const where = [['clubId', '==', this.id]]
    this.$store.dispatch('ClubTeamsModule/fetchAndAdd', { clauses: { where } })
      .catch(error => {
        if (error === 'preventInitialDocInsertion') {
          alert(this.$t('not-allowed-to-delete'))
        }
      })
  },
  methods: {
    openDialogModal () {
      this.dialogModal = true
    },
    create () {
      const team = {
        clubId: this.id,
        name: this.teamForm.name,
        gender: this.teamForm.gender,
        ageGroup: (this.teamForm.ageGroup as any).name
      }
      this.$store.dispatch('ClubTeamsModule/set', team).then(() => {
        this.dialogModal = false
      })
    },
    async hasMatches (teamId: string) {
      const home = firebase.firestore().collection('matches').where('homeTeam.teamId', '==', teamId).get()
      const away = firebase.firestore().collection('matches').where('awayTeam.teamId', '==', teamId).get()
      const [homeQuerySnapshot, awayQuerySnapshot] = await Promise.all([
        home,
        away
      ])
      const homeArray = homeQuerySnapshot.docs
      const awayArray = awayQuerySnapshot.docs
      const macthesArr = homeArray.concat(awayArray)
      return macthesArr
    },
    unlink (teamId: string) {
      this.hasMatches(teamId).then(result => {
        if (result.length === 0) {
          this.$store.dispatch('ClubTeamsModule/delete', teamId)
          return
        }
        this.message = this.$t('cannot-delete-matches-attached')
      })
    }
  }
}
