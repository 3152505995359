
import Vue from 'vue'

export default Vue.extend({
  name: 'ClubPlayersPage',
  data () {
    return {
      search: '',
      filter: this.$t('all'),
      gender: [this.$t('all'), this.$t('female'), this.$t('male')],
      headers: [
        {
          text: this.$t('name'),
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('gender'),
          sortable: true,
          value: 'gender'
        },
        { value: 'actions', sortable: false }
      ]
    }
  },
  created () {
    const where = [
      ['current', '==', this.$route.params.id]
    ]
    this.$store.dispatch('PlayersModule/fetchAndAdd', { clauses: { where } })
  }
})
